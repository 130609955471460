/* eslint no-console:0 */
// ic_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "targetId",
    "invoiceComponentId",
    "primaryButton",
    "availableText",
    "unavailableText"
  ]

  connect() {
    console.log("Connected Delete Controller");
  }

  processDelete(e) {
    e.preventDefault()
    var btn = this.primaryButtonTarget;
    var availableText = this.availableTextTarget;
    var unavailableText = this.unavailableTextTarget;

    if (btn.innerText != "Removing...") {
      //btn.innerText = "Removing..."
      availableText.classList.add("hidden");
      unavailableText.classList.remove("hidden");

      // This handles case in testing where there is no CSRF
      var csrfNode = document.querySelector("meta[name='csrf-token']");
      var csrfToken = ""
      if (csrfNode) {
        csrfToken = csrfNode.content;
      } else {
        ""
      }

      var target = this.targetIdTarget.dataset["value"];

      var invoiceComponentIds = [];
      this.invoiceComponentIdTargets.forEach(function (obj) {
        invoiceComponentIds.push(obj.dataset["value"])
      });

      let data = {
        target_id: target,
        invoice_components_id: invoiceComponentIds
      };
      console.log(data);

      fetch(this.data.get("url"), {
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
        },
        method: "POST",
        body: JSON.stringify(data)
      }).then(res => {
        // This returns the JOB ID
        //console.log("RES: " + res)
        return res.json()
      }).then(json => {
        //console.log(json);
        var jr_id = json['job_record_id'];
        var jr_url = json['status_url'];
        //console.log(jr_id);
        //console.log(jr_url);

        return jr_url;
      }).then(jr_url => promisePoll(function () {
        return fetch(jr_url, {
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
          },
          method: "GET",
        }).then(res => {
          return res.json();
        }).then(json => {
          //console.log(json);
          //console.log((json["state"] == "success"));
          return (json["state"] == "success");
        })
      }, 10000, 400)
      ).then(result => {
        // Polling done, now do something else!
        //console.log("Success");
        //console.log(result);

        var parentRow = this.element.closest("tr");
        parentRow.querySelector(".include_icg_checkbox").checked = false;
        parentRow.querySelector(".include_icg_checkbox").dispatchEvent(new Event('input'));
        parentRow.style.display = "none";

        var delete_alerts_container = document.querySelector("#delete_alerts_container");
        delete_alerts_container.innerHTML += '<div class="alert alert-success" role="alert">Charges have been removed succesfully.</div>';

        //btn.innerText = "Remove All Units";
        availableText.classList.remove("hidden");
        unavailableText.classList.add("hidden");
        //btn.disabled = false;
      }).catch(function () {
        //console.log("Polling timedn out");
        var delete_alerts_container = document.querySelector("#delete_alerts_container");
        delete_alerts_container.innerHTML += '<div class="alert alert-danger" role="alert">Charges could not be removed. Please try again</div>';

        //btn.disabled = false;
        availableText.classList.remove("hidden");
        unavailableText.classList.add("hidden");
      });
    }
  }
}

/* eslint no-console:0 */
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = { 
        url: String

    }
    static targets = [ 
        "invoiceComponent", 
        "invoiceComponentCheckbox", 
        "businessSelector",
        "invoiceComponentVat",
        "invoiceComponentNoVat",
    ]

    connect() {
        console.log("Connecting to Invoice Preview controller");
        window.invoice_preview_sending_state = false; //Reset the sending state (otherwise it'll persist between page loads)
        this.setCheckboxesInitial();
    }

    vatRegistered() {
        let business = this.businessSelectorTarget.options[this.businessSelectorTarget.selectedIndex]
        let vatRegistered = (business.dataset.vatRegistered == 'true');
        console.log("Vat Registered: "+vatRegistered);
        return vatRegistered;
    }

    setCheckboxesInitial() {
        if(!this.vatRegistered()) { 
            this.invoiceComponentVatTargets.forEach((element, index) => {
                element.querySelector('.include_icg_checkbox').checked = false;
                element.querySelector('.include_icg_checkbox').disabled = true;
            });
        }
        this.updateFigures();
    }

    setCheckboxes() {
        if(this.vatRegistered()) {
            this.invoiceComponentNoVatTargets.forEach((element, index) => {
                element.querySelector('.include_icg_checkbox').checked = false;
                element.querySelector('.include_icg_checkbox').disabled = false;
            });
            this.invoiceComponentVatTargets.forEach((element, index) => {
                element.querySelector('.include_icg_checkbox').checked = true;
                element.querySelector('.include_icg_checkbox').disabled = false;
            });
        } else {
            this.invoiceComponentNoVatTargets.forEach((element, index) => {
                element.querySelector('.include_icg_checkbox').checked = true;
                element.querySelector('.include_icg_checkbox').disabled = false;
            });
            this.invoiceComponentVatTargets.forEach((element, index) => {
                element.querySelector('.include_icg_checkbox').checked = false;
                element.querySelector('.include_icg_checkbox').disabled = true;
            });

        }
        this.updateFigures();
    }


    // This is copied from old AJAX stuff. Needs a re-factor
    controlSubmitEnabledStatus() {
      let enabled_count   = 0;
      let disabled_count  = 0;

      $(".invoice_component_group_row").each(function( index ) {
          if($(this).find("input[type=\"checkbox\"]").is(':checked')) {
            enabled_count   += 1;
          } else {
            disabled_count  += 1;
          }
      });

      if( enabled_count > 0 ) {
        $("#invoice_preview_create_button").removeAttr("disabled");
      } else {
        $("#invoice_preview_create_button").prop("disabled", "disabled");
      }
      window.update_button_wait = false;
    }

    updateFigures(iteration = 0) {
        // Reset our wait incase it's stuck on something
        if(iteration > 7) { 
            window.invoice_preview_sending_state = false;
        }

        // If we're waiting on a previous update, return and try again
        if( window.invoice_preview_sending_state == true) {
          setTimeout(() => { this.updateFigures(iteration + 1); }, 500);
          return;
        }
        window.invoice_preview_sending_state = true;
        if( $(".invoice_component_group_row").size() == 0) { return }       

        // Clear the existing values
        $('#inc_vat_total').html("...");
        $('#ex_vat_total' ).html("...");
        $('#vat_total'    ).html("...");

        let icgs = {}
        $(".invoice_component_group_row").each(function( index ) {
            let icg = {}
            icg.ic_arr = $(this).find(".icg_ics").val();
            if($(this).find("input[type=\"checkbox\"]").is(':checked')) {
                icg.included = "1"
            } else {
                icg.included = "0"
            }
            icgs[String(index)] = icg;
        });

        let data = {}
        data.target_id                  = $("#invoice_preview_target_id").val();
        data.invoice_date               = $("#invoice_preview_invoice_date").val();
        data.should_send_email          = $("#invoice_preview_should_send_email").val();
        data.business_id                = $("#invoice_preview_business_id").val();
        data.invoice_component_groups   = icgs;

        let url = this.urlValue;
        $.ajax({ 
          type: "POST",
          url: url,
          data: data,
          success: function(return_data) {
            //console.log(return_data);
            $('#inc_vat_total').text(return_data["inc_vat_total"]);
            $('#ex_vat_total').text(return_data["ex_vat_total"]);
            $('#vat_total').text(return_data["vat_total"]); 
            $("#vat_breakdown_table").html("");
            let new_html_str = ""
            $.each( return_data["vat_breakdown"], function(i, item) {
              new_html_str += "<tr><td>"+item.net+"</td><td>"+item.rate_percentage+"</td><td>"+item.vat+"</td></tr>";
            });
            $("#vat_breakdown_table").html(new_html_str);
            console.log("Completed Update")
            window.preview_wait = false;
            window.invoice_preview_sending_state = false;
          }
        });

        this.controlSubmitEnabledStatus();
    }

    selectAll() {
        event.preventDefault();
        this.setCheckboxes();

        $(".include_icg_checkbox").each(function(index) {
            if($(this).is(':disabled') == false) {
                $(this).prop("checked", true);
            }
        });
    }

    selectMonth(e) {
        event.preventDefault();
        this.setCheckboxes(); // To set the disabled bits
        let month = e.srcElement.dataset['month']
        
        this.invoiceComponentTargets.forEach((element, index) => {
            console.log(element.dataset['month']);
            console.log(month);

            if(element.querySelector('.include_icg_checkbox').disabled == false) {
                if(element.dataset['month'] === month) { 
                    element.querySelector('.include_icg_checkbox').checked = true;
                } else {
                    element.querySelector('.include_icg_checkbox').checked = false;
                }
            }
        });
    }

    deselectAll() {
        event.preventDefault();
        $(".include_icg_checkbox").each(function(index) {
            $(this).removeAttr("checked");
        });
        this.updateFigures();
    }
}
